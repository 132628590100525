<template>

  <section id="hero">
    <div class="container">

      <div class="hero-col-1">
        <div class="col-1-content">

          <h1 class="heading1">
            <span style="z-index: 9;">P</span>
            <span style="z-index: 8;">O</span>
            <span style="z-index: 7;">R</span>
            <span style="z-index: 6;">T</span>
            <span style="z-index: 5;">F</span>
            <span style="z-index: 4;">O</span>
            <span style="z-index: 3;">L</span>
            <span style="z-index: 2;">I</span>
            <span style="z-index: 1;">O</span>
          </h1>

        </div>
      </div>

      <div class="hero-col-2">

        <div class="col-2-content">
          <h3 class="heading3">Krystof<span> Cernik</span></h3>
          <p class="p-1">front-end developer</p>
          <p class="p-2">based in Prague</p>
        </div>

        <div class="col-2-contact">
          <a href="mailto:hello.krystof@gmail.com">
            <p class="p-contact">hello.krystof@gmail.com</p>
            <img src="@/assets/icons/arrow.svg" alt="">
          </a>
        </div>
      </div>

      <div class="hero-col-3">
        <div class="col-3-content">

          <svg width="720" height="3" viewBox="0 0 720 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="-1.31134e-07" y1="1.50006" x2="720" y2="1.5" stroke="#333333" stroke-width="3"/>
          </svg>

          <div class="project project-1">
            <h2
              class="heading2 soon"
              @mouseenter="hovered = 'meetfactory.jpg'"
              @mouseleave="hovered = ''"
            >
              meetfactory <span class="soon">(coming soon)</span>
            </h2>
          </div>

          <svg width="720" height="3" viewBox="0 0 720 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="-1.31134e-07" y1="1.50006" x2="720" y2="1.5" stroke="#333333" stroke-width="3"/>
          </svg>

          <div class="project project-2">
            <a href="https://3dfa.cz/" target="_blank">
              <h2
                class="heading2 router-link"
                @mouseenter="hovered = 'fitness.jpg'"
                @mouseleave="hovered = ''"
              >
                  fitness academy
              </h2>
            </a>
          </div>

          <svg width="720" height="3" viewBox="0 0 720 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="-1.31134e-07" y1="1.50006" x2="720" y2="1.5" stroke="#333333" stroke-width="3"/>
          </svg>

          <div class="project project-3">
            <a href="https://www.bodysolutionrunning.cz/" target="_blank">
              <h2
                class="heading2 router-link"
                @mouseenter="hovered = 'running-club.jpg'"
                @mouseleave="hovered = ''"
              >
                running club
              </h2>
            </a>
          </div>
          
          <svg width="720" height="3" viewBox="0 0 720 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="-1.31134e-07" y1="1.50006" x2="720" y2="1.5" stroke="#333333" stroke-width="3"/>
          </svg>

          <div class="project project-4">
            <a href="https://github.com/KrystofCernik/quintbike_2" target="_blank">
              <h2
                class="heading2 router-link"
                @mouseenter="hovered = 'bike.jpg'"
                @mouseleave="hovered = ''"
              >
                bike manufacturer <span class="soon">(in progress)</span>
              </h2>
            </a>
          </div>

          <svg width="720" height="3" viewBox="0 0 720 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="-1.31134e-07" y1="1.50006" x2="720" y2="1.5" stroke="#333333" stroke-width="3"/>
          </svg>

          <div class="project project-5">
            <a href="https://whimsical-capybara-14d678.netlify.app/" target="_blank">
              <h2
                class="heading2 router-link"
                @mouseenter="hovered = 'threejs.jpg'"
                @mouseleave="hovered = ''"
              >
                three.js + blender <span class="soon">(fun course project)</span>
              </h2>
            </a>
          </div>

          <svg width="720" height="3" viewBox="0 0 720 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="-1.31134e-07" y1="1.50006" x2="720" y2="1.5" stroke="#333333" stroke-width="3"/>
          </svg>
          
        </div>
      </div>

      <img
        class="project-img"
        :src="hovered ? require(`@/assets/images/${hovered}`) : null"
        alt=""
        rel="preload"
      >

    </div>
  </section>

</template>

<script>
import gsap from 'gsap'

export default {
  name: 'HomeView',
  data() {
    return {
      hovered: '',
    }
  },
  watch: {
    hovered() {
      const image = this.$el.querySelector('.project-img')

      if (this.hovered && this.hovered.length) {

        let top = Math.random() * (40 - 10) + 10
        let left = Math.random() * (40 - 20) + 20

        image.style = `top: ${top}%; left: ${left}%; opacity: 1;`
      } else {
        image.style = `opacity: 0;`
      }
    }
  },
  mounted() {
    const tlHero = gsap.timeline();

    tlHero.fromTo('.heading1 span', {
      x: '-120vh'
    },
    {
      x: 0,
      ease: 'power3.out',
      duration: 0.4,
      delay: 1,
      stagger: 0.05
    })

    if (window.innerWidth > 768) {
      tlHero.fromTo('.p-contact', {
        y: '10vh'
      },
      {
        y: 0,
        ease: 'power3.out',
        duration: 0.4,
      })
    }

    tlHero.fromTo('.heading2', {
      y: '-10vh'
    },
    {
      y: 0,
      ease: 'power3.out',
      duration: 0.5,
      delay: 0.15,
      stagger: 0.1
    }, '<')

    tlHero.fromTo('.col-3-content svg', {
      width: 0
    },
    {
      width: '100%',
      ease: 'power3.out',
      duration: 1,
      stagger: 0.1
    }, '<10%')
  }
}
</script>

<style lang="scss" scoped>

#hero {
  height: 100vh;
  width: 100%;

  @media (max-width: 768px) {
    height: 100dvh;
  }

  .container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    .project-img {
      position: absolute;
      max-width: 500px;
      max-height: 500px;
      height: auto;
      opacity: 0;

      @media (max-width: 1280px) {
        max-width: 400px;
        max-height: 400px;
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 1rem;
    }

    .hero-col-1 {
      height: 100%;
      width: 240px;
      z-index: 1;
      background-color: #FAFAFA;

      @media (max-width: 768px) {
        display: none;
      }

      .col-1-content {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
          font-size: 18.3vh;
          transform: rotate(-90deg);
          transition: 0.4s;
          display: flex;

          span {
            display: block;
            overflow: hidden;
            background-color: #FAFAFA;
          }
        }
      }
    }

    .hero-col-2 {
      height: 100%;
      width: calc(60% - 240px);

      @media (max-width: 768px) {
        width: 100%;

      }

      .col-2-content {
        width: 100%;
        height: 17.5%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
      }

      .col-2-contact {
        height: 82.5%;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;

        a {
          position: relative;
          
          p {
            cursor: pointer;
          }

          img {
            position: absolute;
            right: -10%;
            top: 50%;
            transform: translateX(0%) translateY(-40%) rotate(180deg);
            opacity: 0;
            transition: 0.2s ease;
            width: 32px;
          }
        }

        a:hover {
          color: #000;
        }

        a:hover img {
          opacity: 1;
          transform: translateX(25%) translateY(-40%) rotate(180deg);
        }

        @media (max-width: 768px) {
          margin-top: 5rem;
          align-items: flex-start;
          
          a p {
            font-size: 1.5rem;
          }
        }
      }
    }

    .hero-col-3 {
      height: 100%;
      width: 40%;

      @media (max-width: 768px) {
        width: 100%;
      }

      .col-3-content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        svg {
          width: 100%;
          transform-origin: right;
        }

        .project {
          width: 100%;
          height: calc(100% / 5);
          overflow: hidden;

          a {
            transition: 0.2s ease;
          }

          h2 {
            margin-top: 1.5rem;

            @media (max-width: 768px) {
              font-size: 1.5rem;
            }
          }

          span.soon {
            color: lighten($color: #333333, $amount: 60);
            font-size: 1rem;
          }
        }

        .project a:hover {
          color: #000;
        }
      }
    }
  }
}

</style>
