<template>
  <router-view/>
</template>

<style lang="scss" global>

@font-face {
  font-family: 'Switzer';
  font-weight: 300;
  font-style: normal;
  src: url("@/assets/fonts/Switzer-Light.ttf") format('ttf');
  src: url("@/assets/fonts/Switzer-Light.otf") format('otf');
  src: url("@/assets/fonts/Switzer-Light.woff2") format('woff2');
}

@font-face {
  font-family: 'Switzer';
  font-weight: 400;
  font-style: normal;
  src: url("@/assets/fonts/Switzer-Regular.ttf") format('ttf');
  src: url("@/assets/fonts/Switzer-Regular.otf") format('otf');
  src: url("@/assets/fonts/Switzer-Regular.woff2") format('woff2');
}

@font-face {
  font-family: 'Switzer';
  font-weight: 500;
  font-style: normal;
  src: url("@/assets/fonts/Switzer-Medium.ttf") format('ttf');
  src: url("@/assets/fonts/Switzer-Medium.otf") format('otf');
  src: url("@/assets/fonts/Switzer-Medium.woff2") format('woff2');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  overflow: hidden;
  scroll-behavior: smooth;
}

body {
  background-color: #FAFAFA;
  font-family: 'Switzer';
  color: #333333;
}

h1 {
  font-weight: 500;
  cursor: default;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
  cursor: default;
}

h3 {
  font-size: 2.5rem;
  font-weight: 500;
  cursor: default;
}

p {
  font-size: 2rem;
  font-weight: 400;
  cursor: default;
}

a {
  text-decoration: none;
  color: #333333;
  cursor: pointer;
}

.router-link {
  cursor: pointer;
}

</style>
